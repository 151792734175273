<template>
  <div>
    <div class="mary-wrapper row">
      <img
          class="ranking-top"
          loading="lazy"
          alt=""
          :src="require('@/assets/images/ranking/top.png')"
          :srcset="`${require('@/assets/images/ranking/top.png')} 1x, ${require('@/assets/images/ranking/top@2x.png')} 2x`"
      />
      <div class="mary-description-wrapper ranking-description-wrapper">
        <div class="mary-description-text">
          The <span>lottery tickets obtained</span>by members during the activity period from October to December will be counted as ranking points. Ranking points will be counted on <span>12/27</span> and rewards will be automatically distributed.
        </div>
        <div class="btn btn-orange btn-adapter row pointer" @click="gotoSpin">GET BIG LOTTO TICKET</div>
      </div>

      <div class="ranking-userinfo">
        <div class="userinfo-avatar item row">
          <img
              style="width: 64px; margin-right: 8px"
              loading="lazy"
              alt=""
              :src="require('@/assets/images/spin/section-2/userinfo/avatar@2x.png')"
          />
          <div>
            <span style="font-size: 14px; color: #fff">{{ user.account }}</span>
            <i style="color: #FFE318"><p>Deposit any amount {{ user.any_deposit ? 1 : 0 }} /1</p></i>
          </div>
        </div>
        <div class="userinfo-points-wrapper">
          <div class="userinfo-points row">
            <span>My Points</span>
            {{ rankingList.user.amount.toLocaleString() }}
          </div>
          <div class="userinfo-points row">
            <span>My Ranking</span>
            {{ rankingList.user.ranking.toLocaleString() }}
          </div>
          <div class="userinfo-points row">
            <span>My Prizes</span>
            {{ rankingList.user.rewardAmount.toLocaleString() }}
          </div>
        </div>
      </div>

      <div class="txt">
        The bonus will be distributed to your account within 24 hours after the event ends. Thank you for your enthusiastic participation.
      </div>

      <div class="prizes-wrapper ranking-list-wrapper row">
        <div class="ranking-list-container row">
          <div v-for="(v, index) in rankingList.data.slice(0, halfLength)" :key="`prizes_${index}`" :class="['ranking-list-row', 'row', index === 0 ? 'ranking-list-row-first' : '']">
            <div class="left row">
              <div v-if="v.ranking <= 3" class="row"><img :src="RANKING_CROWN[v.ranking-1].img"/></div>
              <div v-else class="row">{{ v.ranking }}</div>
              <span>{{ v.name }}</span>
            </div>
            <span class="row" style="height: 60%"><i>POINTS：</i><i :class="[index < 3 ? 'ranking-list-row-amount' : '']">{{ v.count.toLocaleString() }}</i></span>
          </div>
        </div>
        <div class="ranking-list-container row">
          <div v-for="(v, index) in rankingList.data.slice(halfLength)" :key="`prizes_${index}`" class="ranking-list-row row">
            <div class="left row">
              <div v-if="v.ranking <= 3" class="row"><img :src="RANKING_CROWN[v.ranking-1].img"/></div>
              <div v-else class="row">{{ v.ranking }}</div>
              <span class="row">{{ v.name }}</span>
            </div>
            <span class="row"><i>POINTS：{{ v.count.toLocaleString() }}</i></span>
          </div>
          <div v-if="rankingList.data.length % 2 > 0" :key="`prizes_extra}`" class="ranking-list-row row empty-space">
            <div class="left row">
            </div>
          </div>
        </div>
      </div>
      <div class="more-btn">
        <div class="yellow-btn row" style="padding: 0" :class="[loading || dataEnd ? 'yellow-btn-disable' : 'pointer']" @click="moreRank">
          <span>MORE RANKING</span>
        </div>
      </div>

      <div class="prizes-wrapper row" style="margin-top: 50px">
        <div class="prizes-title-wrapper">
          <span class="prizes-title-shadow">LIST OF PRIZES</span>
          <span class="prizes-title">LIST OF PRIZES</span>
        </div>

        <div class="list-of-prizes-wrapper">
          <div class="list-of-prizes-title row">
            <div class="row">Ranking</div>
            <div class="row">Bonus</div>
          </div>
          <div>
            <div
                v-for="(v, index) in LOTTERY_PRIZES"
                :key="`${index}_LOTTERY_PRIZES`"
                class="list-of-prizes-body-row row">
              <div class="row" style="border-right: 1px solid #C9C9C9" :style="index === LOTTERY_PRIZES.length - 1 ? 'border-radius: 0 0 0 20px' : ''">{{ v.ranking }}</div>
              <div class="row" :style="index === LOTTERY_PRIZES.length - 1 ? 'border-radius: 0 0 20px 0' : ''">{{ v.bonus }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalCommon v-model="showModal" @cancel="showModal = false" :dark="true">
      <template v-slot:title>
        The event has ended, thank you for your enthusiastic participation
      </template>
    </ModalCommon>
  </div>
</template>

<script>
import {getRank} from "@/apis/common"
import { RANKING_CROWN, LOTTERY_PRIZES } from "@/data/define"
import {defaultUser} from "@/data/user"
import ModalCommon from "@/components/modal/ModalCommon/index"
import {getDomainId} from "@/utils"

export default {
  name: "index",
  props: {
    user: {
      type: Object,
      default: () => defaultUser
    }
  },
  components: {
    ModalCommon,
  },
  computed: {
    halfLength() {
      if (!this.rankingList.data.length) return 0
      return Math.ceil(this.rankingList.data.length / 2)
    }
  },
  data() {
    return {
      showModal: false,
      rankingList: {
        data: [],
        user: {
          amount: 0,
          ranking: '-',
          rewardAmount: '-',
        }
      },
      RANKING_CROWN,
      LOTTERY_PRIZES,
      loading: false,
      size: 20,
      page: 0,
      dataEnd: true,
    }
  },
  activated() {
  },
  watch: {
  },
  methods: {
    async getRank(page = 0) {
      try {
        this.loading = true
        const res = await getRank({
          account: this.user.account,
          page: page,
          size: this.size,
          user_from: getDomainId(),
        })
        const { status, data } = res
        this.page = page

        if (status == 200) {
          const d = JSON.parse(JSON.stringify(data))

          if (page === 0)
            this.rankingList.data = d.ranking
          else
            this.rankingList.data = this.rankingList.data.concat(d.ranking)

          this.rankingList.user = Object.assign(this.rankingList.user, d.user)
          this.dataEnd = d.ranking.length < this.size
        }
      } catch (e) {
        console.log('getRank catch')
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    moreRank() {
      if (!this.dataEnd)
        this.getRank(this.page + 1)
    },
    gotoSpin() {
      const targetDate = new Date('2024-12-27T00:00:00')
      const currentDate = new Date()
      if (currentDate > targetDate)
        this.showModal = true
      else
        this.$emit('indexChange', 0)
    }
  },
  beforeDestroy() {
  }
}
</script>

<style lang="css" src="@/assets/styles/ranking.css"></style>

<style scoped>

</style>
