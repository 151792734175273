import request from './request'

export const getBroadcast = params => {
  return request.get(`${process.env.VUE_APP_DOMAIN}/api/v1/app/getBroadcast`, { params });
}

export const getUser = params => {
  return request.get(`${process.env.VUE_APP_DOMAIN}/api/v1/app/getUser`, { params });
}

export const getTodayAnyDeposit = params => {
  return request.get(`${process.env.VUE_APP_DOMAIN}/api/v1/app/getTodayAnyDeposit`, { params });
}

export const getRank = params => {
  return request.get(`${process.env.VUE_APP_DOMAIN}/api/v1/app/getRank`, { params });
}

export const getUserCoupons = params => {
  return request.get(`${process.env.VUE_APP_DOMAIN}/api/v1/app/getUserCoupons`, { params });
}

export const getUserLotteryCoupons = params => {
  return request.get(`${process.env.VUE_APP_DOMAIN}/api/v1/app/getUserLotteryCoupons`, { params });
}

export const getUserPrizeCoupons = params => {
  return request.get(`${process.env.VUE_APP_DOMAIN}/api/v1/app/getUserPrizeCoupons`, { params });
}

export const spin = (params = {}) => {
  return request.post(`${process.env.VUE_APP_DOMAIN}/api/v1/app/spin`, params)
}

export const createUserPromo = (params = {}) => {
  return request.post(`${process.env.VUE_APP_DOMAIN}/api/v1/app/createUserPromo`, params)
}



