<template>
  <div v-if="visible" class="modal-overlay row" :style="dark ? 'background-color: #18110B' : ''">
    <div class="modal-content-login row">
      <div class="modal-body row">
        <div class="model-body-a row">
          <h1 style="line-height: 37px">
            LOGIN
          </h1>
        </div>
        <div class="password-wrapper" style="margin-bottom: 20px">
          <input v-model="username" placeholder="Username" id="password-input">
          <span class="toggle-password">
          </span>
        </div>
        <div class="password-wrapper">
          <input
              v-model="password"
              :type="eyes ? 'password' : 'text'"
              placeholder="Password" id="password-input">
          <span class="toggle-password">
            <img src="./eyes.svg" style="width: 18px; height: 18px" @click="eyes = !eyes" />
          </span>
        </div>
        <div class="orange-button-wrapper row" style="margin-top: 20px; margin-bottom: 10px">
          <div class="orange-button row pointer" style="color: #fff" @click="handleLogin">
            LOGIN
          </div>
        </div>
        <div style="font-size: 14px; color: #6D6D6D; ">
          No account yet? <span @click="gotoRegister" class="pointer" style="color: rgb(38, 109, 250)">Register now</span>
        </div>
      </div>

      <img
          @click="handleCancel"
          class="btn-close-login pointer"
          loading="lazy"
          alt=""
          :src="require('@/components/modal/close.svg')"
      />
    </div>
  </div>
</template>

<script>
import { lockScroll, unlockScroll } from '@/assets/js/common'
import { getUser } from "@/apis/common"
import { getRegisterUrl, getDomainId } from "@/utils"

export default {
  name: 'Modal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visible() {
      return this.value
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.lockScroll()
      } else {
        this.unlockScroll()
      }
    },
  },
  data() {
    return {
      username: "",
      password: "",
      eyes: false,
      loginMsg: "",
      loading: false,
    }
  },
  methods: {
    handleCancel() {
      this.username = ""
      this.password = ""
      this.eyes = false
      this.loginMsg = ""
      this.$emit('cancel')
    },
    lockScroll() {
      lockScroll()
    },
    unlockScroll() {
      unlockScroll()
    },
    handleLogin() {
      if (!this.username)
        return

      this.userLogin()
    },
    async userLogin() {
      if (this.loading)
        return

      try {
        this.loading = true
        const platform = getDomainId()
        const param = {
          account: this.username,
          password: this.password,
          user_from: platform,
        }

        const res = await getUser(param)
        const { status } = res

        if (status == 200) {
          if (res.data.data.length) {
            let d =  Object.assign({}, JSON.parse(JSON.stringify(res.data.data[0])))
            d.login = true
            this.$emit('login', d)
            sessionStorage.setItem('account', d.account)
          } else {
            const d = {
              account: this.username,
              login: true,
              available_coupon: 0,
              today_bet_amount: 0,
              today_deposit_amount: 0,
            }
            this.$emit('login', d)
            sessionStorage.setItem('account', this.username)
          }
        }
      } catch (e) {
        console.log('userLogin catch')
        console.log(e)
      } finally {
        this.loading = false
        this.$emit('cancel')
      }
    },
    gotoRegister() {
      const url = getRegisterUrl()
      window.open(url, '_blank')
    }
  },
  beforeDestroy() {
    this.unlockScroll()
  },
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000B2;
  z-index: 1000;
  flex-direction: column;
}
.modal-content-login {
  padding: 24px 36px;
  border-radius: 36px;
  position: relative;
  max-width: 375px;
  height: 375px;
  width: 100%;
  background: linear-gradient(197.1deg, #F4110B -121.32%, #FAABA7 -33.55%, #FDFFFD 70.74%);
  position: relative;
}
.modal-body {
  width: 100%;
  height: 100%;
  flex-direction: column;
  text-align: center;
  justify-content: start;
}
.model-body-a {
  margin-bottom: 25px;
}
h1 {
  line-height: 20.7px;
  font-weight: 600;
  font-size: 18px;
}
h2 {
  margin-top: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.1px;
  color: #6D6D6D;
}
.btn-close-login {
  width: 48px;
  margin-top: -18px;
  position: absolute;
  bottom: -70px;
}
#password-input {
  width: 303px;
  padding-right: 40px; /* 给右边的图标留空间 */
  padding-left: 10px;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  outline: none;
}

#password-input:focus {
  border: 1px solid #D6DAE0;
  box-shadow: none;
}

#password-input::placeholder {
  color: #D6DAE0;
}

.password-wrapper {
  position: relative;
  width: 300px;
}

.toggle-password {
  position: absolute;
  top: 16px;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
  color: #aaa;
}
</style>
