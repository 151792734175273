<template>
  <div class="container">
    <div class="header-bg-wrapper">
      <Banner :index="tabIndex" />
<!--      <img-->
<!--          class="header-left"-->
<!--          loading="lazy"-->
<!--          alt=""-->
<!--          :src="require('@/assets/images/pc/header-left@1x.png')"-->
<!--      />-->
<!--      <img-->
<!--          class="header-circle"-->
<!--          loading="lazy"-->
<!--          alt=""-->
<!--          :src="require('@/assets/images/pc/header-circle@1x.png')"-->
<!--      />-->
<!--      <img-->
<!--          class="header-balloon"-->
<!--          loading="lazy"-->
<!--          alt=""-->
<!--          :src="require('@/assets/images/pc/header-balloon@1x.png')"-->
<!--      />-->
<!--      <img-->
<!--          class="header-txt"-->
<!--          loading="lazy"-->
<!--          alt=""-->
<!--          :src="require('@/assets/images/pc/header-txt@1x.png')"-->
<!--      />-->

      <div class="marquee-wrapper row">
        <img class="marquee-brocast-img" :src="require('@/assets/images/svg/broadcast.svg')" style="width: 24px; margin-right: 10px" />
        <div class="marquee-container">
          <div class="marquee">
            <div class="marquee-item" v-for="(item, index) in broadcast" :key="index">
              <span class="marquee-text">Congratulations to {{ item.name }}, won</span>
              <img :src="SPIN_REWARDS[item.reward_id].img" alt="icon" class="item-icon" />
            </div>
            <div class="marquee-item" v-for="(item, index) in broadcast" :key="'clone-' + index">
              <span class="marquee-text">Congratulations to {{ item.name }}, won</span>
              <img :src="SPIN_REWARDS[item.reward_id].img" alt="icon" class="item-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tabs-wrapper row pointer">
      <div class="tab-wrapper row" v-for="(item, index) in tabs" :key="`${index}_h_tab`" @click="tabIndex = index" :class="tabIndex === index ? '' : 'tab-wrapper-inactive'">
        {{ item }}
      </div>
    </div>

    <Spin
        v-show="tabIndex === 0"
        ref="spinRef"
        @onListClose="updateUser"
        @login="onLogin"
    />
    <Ranking
        v-show="tabIndex === 1"
        :user=user
        ref="rankingRef"
        @indexChange="indexChange"
    />
    <Lottery
        v-show="tabIndex === 2"
        :user=user
        ref="lotteryRef"
        @indexChange="indexChange"
    />
  </div>
</template>

<script>
import { SPIN_REWARDS } from '@/data/define'
import Spin from "@/components/Spin"
import Ranking from "@/components/Ranking"
import Lottery from "@/components/Lottery"
import {defaultUser} from "@/data/user"
import {getBroadcast, getTodayAnyDeposit} from "@/apis/common"
import Banner from "@/components/banner"
import {getDomainId, getRegisterUrl, isYesterday} from "@/utils"

export default {
  name: 'App',
  components: {
    Spin,
    Ranking,
    Lottery,
    Banner,
  },
  data() {
    return {
      broadcast: [],
      loading: false,
      tabIndex: 0,
      SPIN_REWARDS,
      user: { ...defaultUser },
      broadcastInterval: null,
      tabs: ['BER MONTH Lucky Spin!', 'Total earned points! Ranking!', 'Millionaire\'s! Luck!'],
    }
  },
  mounted() {
    // this.checkAccount()

    this.getBroadcast().finally(() => {
      this.broadcastInterval = setInterval(() => {
        this.getBroadcast()
      }, 120000)
    })
  },
  activated() {
    // this.checkAccount()
    this.updateUser()
  },
  watch: {
    tabIndex(newIndex) {
      if (newIndex === 0) {
        this.updateUser()
      } else if (newIndex === 1) {
        this.$refs.rankingRef.getRank()
      }
    },
  },
  methods: {
    checkAccount() {
      if (getDomainId() === 1) {
        const account = sessionStorage.getItem('account')
        if (!account) {
          window.location.replace(getRegisterUrl())
        }
      }
    },
    onLogin(user) {
      this.user = { ...user }
      this.getTodayAnyDeposit()
    },
    async getTodayAnyDeposit() {
      const res = await getTodayAnyDeposit({
        account: this.user.account,
        user_from: getDomainId(),
      })

      const {status, data} = res
      if (status == 200) {
        if (data.data.length > 0) {

          const getTime = new Date(data.data[0]['get_time'])
          const today = new Date()
          const getTimeStr = getTime.getFullYear() + '-' + getTime.getMonth() + '-' + getTime.getDate()
          const todayStr = today.getUTCFullYear() + '-' + today.getUTCMonth() + '-' + today.getUTCDate()
          if (getTimeStr === todayStr) {
            this.user.any_deposit = true
            return
          }

          if (getDomainId() !== 1) {
            if (isYesterday(getTime))
              this.user.any_deposit = true
          }
        }
      }
    },
    updateUser() {
      this.$refs.spinRef.tryLogin().then((res) => {
        this.user = res
        this.getTodayAnyDeposit()
      })
    },
    indexChange(index) {
      if (this.tabIndex !== index)
        this.tabIndex = index
    },
    async getBroadcast() {
      try {
        this.loading = true
        const res = await getBroadcast()
        const { status } = res

        if (status == 200) {
          if (res.data.data.length) {
            this.broadcast = res.data.data
            // console.log(this.broadcast)
          }
        }
      } catch (e) {
        console.log('getBroadcast catch')
        console.log(e)
      } finally {
        this.loading = false
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.broadcastInterval)
  }
}
</script>

<style lang="css" src="@/assets/styles/index.css"></style>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
</style>

<style>
@font-face {
  font-family: HYLiLiangHeiJ;
  src: url('../assets/fonts/hyliliangheij.ttf') format('truetype');
}
</style>
